'use client'

import PrimaryButton from '@components/Home/PrimaryButton/Button'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import Image from 'next/image'
import { When } from 'react-if'
import { ProductCardButton } from '@components/Home/ProductCardButton/ProductCardButton'
import { domainConfig } from '@configs/domain.config'
import { Domain } from '@enums/Domain'
import { ProductItem } from '@interfaces/product'
import AckoDriveProductBanner from '@components/ProductBanner/ProductBanner'
import styles from './HomeLandingWidget.module.scss'
import { HOME_PAGE_ROUTE, VARIANT } from './constants'

interface ADBannerDataProps {
  heading: string
  subheading: string
  logoUrl: string
  product: ProductItem[]
}
interface HomeLandingWidgetProps {
  heading: string
  subheading: string
  productHeading: string
  product: ProductItem[]
  ctaText: string
  ctaTextMobile: string
  heroImage: string
  productBannerData: ADBannerDataProps
  productsDisclaimer: string
}

const CURRENT_VARIANT = VARIANT.WITH_PRODUCT_CARDS

const HomeLandingWidget = ({
  heading,
  subheading,
  product,
  productBannerData,
  productsDisclaimer,
  heroImage,
  ctaText,
  ctaTextMobile,
  productHeading,
}: HomeLandingWidgetProps) => (
  <div
    id={HomeComponentType.LandingWidget}
    className={`${styles.widgetContainer} ${
      CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS
        ? styles.variantA
        : CURRENT_VARIANT === VARIANT.WITH_PRODUCT_CARDS
          ? styles.variantB
          : ''
    }`}>
    <div className={styles.dataContainer}>
      <div className={styles.widgetWrapper}>
        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
        {!!subheading && <h1 className={styles.subHeading}>{subheading}</h1>}

        <When condition={CURRENT_VARIANT === VARIANT.WITH_PRODUCT_CARDS}>
          <div className={`${styles.productCardWrappers} ${styles.extraMarginTop}`}>
            {product?.map(productItem => (
              <ProductCardButton key={productItem?.id} featured={productItem?.featured} product={productItem} />
            ))}
          </div>
        </When>
      </div>
      <When condition={CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS}>
        <p className={styles.productHeading}>{productHeading}</p>
        <div className={styles.productsDesktop}>
          {product?.map(product => <p className={styles.product}>{product.shortName}</p>)}
        </div>
        <div className={styles.ctaWeb}>
          <PrimaryButton
            link={HOME_PAGE_ROUTE}
            text={ctaText}
            segmentEventName={domainConfig[Domain.ACKO].events.homePage}
            segmentEventProps={{ funnel: 'entry' }}
          />
        </div>
      </When>
      <AckoDriveProductBanner
        logo={productBannerData.logoUrl}
        heading={productBannerData.heading}
        subHeading={productBannerData.subheading}
        product={productBannerData.product}
        productsDisclaimer={productsDisclaimer}
      />
    </div>
    <When condition={CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS}>
      <div className={styles.heroImageContainer}>
        <Image src={heroImage} alt="hero image" fill priority objectFit="contain" />
      </div>
    </When>
    <When condition={CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS}>
      <div className={styles.productsMobile}>
        {product?.map(product => <p className={styles.product}>{product.shortName}</p>)}
      </div>
      <div className={styles.ctaMobile}>
        <PrimaryButton
          link={HOME_PAGE_ROUTE}
          text={ctaTextMobile}
          segmentEventName={domainConfig[Domain.ACKO].events.homePage}
          segmentEventProps={{ funnel: 'entry' }}
        />
      </div>
    </When>
  </div>
)

export default HomeLandingWidget
