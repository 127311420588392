export enum ProductName {
    CAR = 'car',
    BIKE = 'bike',
    TRAVEL = 'travel',
    HEALTH = 'health',
    LIFE = 'life',
    ACKO_DRIVE_GARAGE_SERVICE = 'service',
    ACKO_DRIVE_BUY_CAR = 'buy',
    ACKO_DRIVE_CAR_VALUATION = 'sell',
}
