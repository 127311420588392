import Image from 'next/image'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import { ProductCardButton } from '@components/Home/ProductCardButton/ProductCardButton'
import { Domain } from '@enums/Domain'
import { ProductItem } from '@interfaces/product'
import styles from './productbanner.module.scss'

export interface IProductBannerProps {
  logo: string
  heading: string
  subHeading: string
  product: ProductItem[]
  productsDisclaimer: string
}

const AckoDriveProductBanner = ({ logo, heading, subHeading, product, productsDisclaimer }: IProductBannerProps) => (
  <div id={HomeComponentType.ProductBanner}>
    <div className={styles.ackoDriveProductContainer}>
      <span className={styles.ackoDriveProductHeading}>{heading}</span>
      <div className={styles.ackoDriveProductSubHeading}>
        <span className={styles.subHeadingText}>{subHeading}&nbsp;</span>
        <Image src={logo} width={90} height={28} alt="product logo" className={styles.logo} />
      </div>
      <div className={styles.ackoDriveProductWrapper}>
        {product?.map(productItem => (
          <ProductCardButton
            key={productItem?.id}
            product={productItem}
            featured={productItem?.featured}
            imageSize={136}
            domain={Domain.ACKODRIVE}
          />
        ))}
      </div>
      <div className={styles.disclaimer}>{productsDisclaimer}</div>
    </div>
  </div>
)

export default AckoDriveProductBanner
