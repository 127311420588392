/* eslint-disable @typescript-eslint/no-floating-promises */

'use client'

import Image from 'next/image'
import { trackEvent } from '@acko-tech/building-blocks.ui.acko-analytics'
import { r2d2 } from '@utils/utils'
import { homePageFunnel } from '@configs/segmentEvents.config'
import { domainConfig } from '@configs/domain.config'
import { Domain } from '@enums/Domain'
import { useState } from 'react'
import { homePageCommonProps } from '@utils/segmentProperties'
import { ProductItem } from '@interfaces/product'
import Arrow from '@public/images/homepage-arrow.svg'
import { PRODUCT_BUTTON_EXPERIMENT_NAME } from '@constants/homeSegmentEvent'
import { getUrlWithQueryParams } from '@utils/queryParamUtils'
import styles from './ProductCardButton.module.scss'
import { ProductModal } from './ProductModal/ProductModal'

interface ProductCardButtonProps {
  product: ProductItem
  featured?: boolean
  imageSize?: number
  domain?: Domain
}

export const ProductCardButton = ({
  product,
  featured = false,
  imageSize = 96,
  domain = Domain.ACKO,
}: ProductCardButtonProps) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <a
        onClick={e => {
          e.preventDefault()
          ;(async function IFFE() {
            const commonProps = homePageCommonProps()
            try {
              const trackingEvents = [
                trackEvent(domainConfig[domain].events.homePage, {
                  ...commonProps,
                  funnel: homePageFunnel[product?.shortName],
                  journey: product?.journey,
                  type: product?.shortName,
                  experiment_name: PRODUCT_BUTTON_EXPERIMENT_NAME,
                  from_page: window.location.href,
                }),
                r2d2(
                  {
                    ekind: domainConfig[domain].events.homePage,
                    odata: { product: product?.shortName },
                  },
                  `${window.location.origin}/api/r2d2/`
                ),
              ]
              await Promise.allSettled(trackingEvents)
            } catch (e) {
              console.error('track event error')
            }
            domainConfig[domain].clickBehaviour[commonProps.platform][product?.shortName].isModalView
              ? setShow(true)
              : (window.location.href = getUrlWithQueryParams(
                  domainConfig[domain].clickBehaviour[commonProps.platform][product?.shortName].clickUrl
                ))
          })()
        }}
        style={{ gridArea: `${product?.shortName}` }}
        className={`${styles.productCardButton} ${domainConfig[domain].style(styles)} ${featured && styles.featured}`}
        href={product?.url}>
        <span className={styles.productHeading}>{product?.name}</span>
        {product.description && <span className={styles.productSubHeading}>{product?.description}</span>}
        {product.label && <span className={styles.productOffer}>{product?.label}</span>}
        <Image
          src={product?.iconImage}
          alt={product?.name}
          width={imageSize}
          height={imageSize}
          className={styles.productImage}
        />
        <Arrow className={styles.arrow} />
      </a>
      {product?.modalData && <ProductModal modalData={product?.modalData} show={show} setShow={setShow} />}
    </>
  )
}
