import { Domain } from '@enums/Domain'
import { getUrlWithQueryParams } from '@utils/queryParamUtils'
import { ProductName } from '@enums/ProductNames'
import { PLATFORM } from './platform.config'

export const domainConfig = {
    [Domain.ACKO]: {
        events: {
            homePage: 'btn_explore_insurance',
        },
        style: () => '',
        onClick: url => (window.location.href = getUrlWithQueryParams(url)),
        clickBehaviour: {
            [PLATFORM.DESKTOP]: {
                [ProductName.BIKE]: {
                    isModalView: false,
                    clickUrl: '/t/bike',
                },
                [ProductName.CAR]: {
                    isModalView: false,
                    clickUrl: '/t/car',
                },
                [ProductName.HEALTH]: {
                    isModalView: false,
                    clickUrl: '/gi/p/health/segmentV2',
                },
                [ProductName.LIFE]: {
                    isModalView: false,
                    clickUrl: '/life/p/user-info/gender',
                },
                [ProductName.TRAVEL]: {
                    isModalView: false,
                    clickUrl: '/t/travel',
                },
            },
            [PLATFORM.MOBILE]: {
                [ProductName.BIKE]: {
                    isModalView: false,
                    clickUrl: '/t/bike',
                },
                [ProductName.CAR]: {
                    isModalView: false,
                    clickUrl: '/t/car',
                },
                [ProductName.HEALTH]: {
                    isModalView: false,
                    clickUrl: '/gi/p/health/segmentV2',
                },
                [ProductName.LIFE]: {
                    isModalView: false,
                    clickUrl: '/life/p/user-info/gender',
                },
                [ProductName.TRAVEL]: {
                    isModalView: false,
                    clickUrl: '/t/travel',
                },
            },
        },
    },
    [Domain.ACKODRIVE]: {
        events: {
            homePage: 'track_event_complete',
        },
        style: styles => styles.isDrive,
        onClick: url => window.open(getUrlWithQueryParams(url), '_blank'),
        clickBehaviour: {
            [PLATFORM.DESKTOP]: {
                [ProductName.ACKO_DRIVE_BUY_CAR]: {
                    isModalView: false,
                    clickUrl: 'https://ackodrive.com/cars/?utm_source=acko&utm_medium=web',
                },
                [ProductName.ACKO_DRIVE_CAR_VALUATION]: {
                    isModalView: true,
                    clickUrl: 'https://www.ackodrive.com/used/car-valuation?utm_source=acko&utm_medium=web',
                },
                [ProductName.ACKO_DRIVE_GARAGE_SERVICE]: {
                    isModalView: true,
                    clickUrl: 'https://www.acko.com/garage-storefront/ad-services/ackodrive-garage?utm_source=acko&utm_medium=web',
                },
            },
            [PLATFORM.MOBILE]: {
                [ProductName.ACKO_DRIVE_BUY_CAR]: {
                    isModalView: false,
                    clickUrl: 'https://ackodrive.com/cars/?utm_source=acko&utm_medium=mweb',
                },
                [ProductName.ACKO_DRIVE_CAR_VALUATION]: {
                    isModalView: false,
                    clickUrl: 'https://www.ackodrive.com/used/car-valuation?utm_source=acko&utm_medium=mweb',
                },
                [ProductName.ACKO_DRIVE_GARAGE_SERVICE]: {
                    isModalView: false,
                    clickUrl: 'https://www.acko.com/garage-storefront/ad-services/ackodrive-garage?utm_source=acko&utm_medium=mweb',
                },
            },
        },
    },
}
